































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const model = reactive({
      isEnabled: true,
      reserveListFrom: "",
      isCompanionEnabled: false,
      companionPrice: "0",
      additionalInformationAboveFee: "",
      englishAdditionalInformationAboveFee: "",
      resignationFromParticipation: "0",
      additionalInformationPosition: "under",
    });

    const state = reactive({
      empty: false,
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-module`)
        .then(({ data }) => {
          model.isEnabled = data.isEnabled;
          model.reserveListFrom = data.reserveListFrom?.split("T")[0];
          model.isCompanionEnabled = data.isCompanionEnabled;
          model.companionPrice = data.companionPrice;
          model.additionalInformationAboveFee =
            data.additionalInformationAboveFee;
          model.englishAdditionalInformationAboveFee =
            data.englishAdditionalInformationAboveFee;
          model.resignationFromParticipation =
            data.resignationFromParticipation;
          model.additionalInformationPosition =
            data.additionalInformationPosition;
          state.empty = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isEnabled: model.isEnabled,
        reserveListFrom: model.reserveListFrom || null,
        isCompanionEnabled: model.isCompanionEnabled,
        companionPrice: parseFloat(model.companionPrice) || 0,
        additionalInformationAboveFee:
          model.additionalInformationAboveFee || null,
        englishAdditionalInformationAboveFee:
          model.englishAdditionalInformationAboveFee || null,
        resignationFromParticipation:
          parseFloat(model.resignationFromParticipation) || 0,
        additionalInformationPosition: model.additionalInformationPosition,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/registration-module`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.saveSuccess"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, onSubmit, getErrorMessage };
  },
});
